import { useEffect, useState } from "react"

export default function useMarkdown(path) {
    const [markdown, setMarkdown] = useState('')

    useEffect(() => {
        fetch(path).then((response) => response.text()).then((res) => setMarkdown(res))
    }, [])

    return { markdown }
}
