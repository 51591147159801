import React from "react";
import clsx from "clsx";
import {
    CssBaseline,
    Drawer,
    Box,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    IconButton,
    Container,
    Grid,
    Paper,
    Snackbar,
    ListItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MuiAlert from "@material-ui/lab/Alert";
import ListItems from "./listItems";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import MenuUser from "./menu";
import i18next from "i18next";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import TermsAndConditionsButton from "./terms-conditions-button";

class DashboardView extends React.Component {
    Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };

    render() {
        const classes = this.props.styles;
        const user = JSON.parse(localStorage.getItem('user_customer')) || {
            value: { displayName: "" },
        };
        const { alertReducer, isResumeTrip, t } = this.props;
        const lastAlert = alertReducer.messages.length > 0 ? alertReducer.messages[alertReducer.messages.length - 1] : null
        // to prevent multiple alert repeat in the render
        if (lastAlert)
            this.props.clear()
        return (
            <div className={classes.root} style={{ height: '100vh' }} >
                <CssBaseline />
                <AppBar
                    position="absolute"
                    elevation={1}
                    className={clsx(
                        classes.appBar,
                        this.props.open && classes.appBarShift
                    )}
                >
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="primary"
                            aria-label="open drawer"
                            onClick={this.props.handleDrawerOpen}
                            className={clsx(
                                classes.menuButton,
                                this.props.open && classes.menuButtonHidden
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="primary"
                            noWrap
                            className={classes.title}
                        >
                            <ListItem button component={Link} to="/home">
                                {i18next.t('dashboard.title')}
                            </ListItem>
                        </Typography>

                        <MenuUser
                            t={t}
                            logout={this.props.logout}
                            name={user.value.displayName || user.value.email}
                            cssClass={classes.username}
                            changeLanguage={this.props.changeLanguage}
                        />
                    </Toolbar>
                </AppBar>

                <Drawer
                    variant="permanent"
                    style={{ height: '100vh' }}
                    classes={{
                        paper: clsx(
                            classes.drawerPaper,
                            !this.props.open && classes.drawerPaperClose
                        ),
                    }}
                    open={this.props.open}
                >
                    <div style={{ display: 'grid', height: '100%', alignContent: 'space-between' }}>
                        <div>
                            <div className={classes.toolbarIcon}>
                                <IconButton onClick={this.props.handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                <ListItems
                                    t={t}
                                    resetData={this.props.resetData}
                                    match={this.props.component.props.match}
                                />
                            </List>
                            <Divider />
                        </div>
                        <div style={{
                            padding: 16
                        }}>
                            <TermsAndConditionsButton />
                        </div>
                    </div>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="xl" className={classes.container}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12}>
                                <Paper elevation={isResumeTrip ? 0 : 1} className={isResumeTrip ? classes.paperContainer : ''}>
                                    {lastAlert && this.props.enqueueSnackbar(lastAlert.message, { variant: lastAlert.type, autoHideDuration: 9000, onClose: this.props.handleCloseAlert })}
                                    {this.props.component}
                                </Paper>
                            </Grid>
                        </Grid>
                        <Box pt={4}>{/* <Copyright /> */}</Box>
                    </Container>
                </main>
            </ div>
        );
    }
}

function Hook(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true); //false (defaultState for open lateral menu) true=open/false=close
    const [openAlert] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const { enqueueSnackbar } = useSnackbar()
    const handleCloseAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        props.clear();
    };
    const trip = useSelector((state) => state.tripReducer.trip)

    return (
        <DashboardView
            styles={classes}
            component={props.component}
            open={open}
            logout={props.logout}
            alertReducer={props.alertReducer}
            authenticationReducer={props.authenticationReducer}
            setOpen={setOpen}
            openAlert={openAlert}
            isResumeTrip={trip != null}
            {...props}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            handleCloseAlert={handleCloseAlert}
            enqueueSnackbar={enqueueSnackbar}
        />
    );
}

export default Hook;
