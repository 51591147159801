import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import termsAndConditions from './termsAndConditions.md'
import ReactMarkdown from "react-markdown";
import useMarkdown from '../../hooks/useMarkdown'

function TermsAndConditionsModal({ visible, handleClose, acceptRules }) {
    const { t } = useTranslation()
    const [isBottom, setIsBottom] = useState(true)
    const { markdown } = useMarkdown(termsAndConditions)

    const onScroll = (event) => {
        const window = event.target
        const bottom = Math.floor(window.scrollTop) + window.clientHeight === window.scrollHeight

        if (bottom) {
            setIsBottom(false)
        }

    };

    return (
        <Dialog
            open={visible}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
            onScroll={(e) => onScroll(e)}
        >
            <DialogTitle>
                <Typography align='center' style={{ fontSize: 25 }}> {t('register.page.tosModal.title')} </Typography>
            </DialogTitle>
            <DialogContent>
                <ReactMarkdown>{markdown}</ReactMarkdown>
            </DialogContent>
            <div style={{ display: 'grid', position: 'sticky', padding: 10, gap: 5 }}>
                {acceptRules && (
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isBottom}
                        onClick={() => {
                            acceptRules()
                            handleClose()
                        }}
                        fullWidth
                        size="large"
                    >
                        {t('register.page.tosModal.acceptButton')}
                    </Button>
                )}
                <Button
                    variant='outlined'
                    color='primary'
                    type='button'
                    onClick={() => handleClose()}
                    fullWidth
                    size='large'
                >
                    {t('register.page.tosModal.cancel')}
                </Button>
            </div>
        </Dialog>
    )
}
TermsAndConditionsModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    acceptRules: PropTypes.func,
}

export default TermsAndConditionsModal